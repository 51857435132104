export const CLOSE_BRACKET = "CLOSE_BRACKET";
export const KEY_DOWN = "KEY_DOWN";
export const CHANGE = "CHANGE";
export const SYNC = "SYNC";
export const BACKSPACE = "BACKSPACE";
export const UNINDENT_NODE = "UNINDENT_NODE";
export const SELECT_LINK = "SELECT_LINK";
export const INDENT_NODE = "INDENT_NODE";
export const INIT_LINK = "INIT_LINK";
export const LINK_CREATED = "LINK_CREATED";
export const LINK_UPDATED = "LINK_UPDATED";
export const INSERT_BREAK = "INSERT_BREAK";
export const INSERT_SOFT_BREAK = "INSERT_SOFT_BREAK";
export const SYNC_LIST_ITEM = "SYNC_LIST_ITEM";
export const TOGGLE_CODE_BLOCK = "TOGGLE_CODE_BLOCK";
